import {
  CreateButton,
  Datagrid,
  DatagridConfigurable,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  SelectColumnsButton,
  ShowButton,
  TopToolbar,
  useListContext,
  useNotify,
  useResourceDefinition,
  useStore,
} from "react-admin";
import { debug_log } from "../utils/debugLog";
import { getStoreKey } from "../utils/getStoreKey";
import { getUniqueUIFilters } from "../utils/getUniqueUIFilters";
import {
  qualifyField,
  qualifyFilter,
  qualifyUIFilter,
  unqualifyField,
} from "../utils/qualifyFilter";
import { JsonStyled } from "./jsonStyled";
import { Card, CardContent } from "@mui/material";
import { Debug } from "./debug";
import omit from "lodash/omit";
import camelCase from "lodash/camelCase";
import PropTypes from "prop-types";
import { Settings } from "../utils/settings";
import { Empty } from "./Empty";
import { getConfig } from "../config/packageSetup";
import { objectMap } from "../utils/tools";

const packageConfig = getConfig();

const getFrequencies = (arr) => {
  const f = {};

  arr.forEach((val) => {
    f[val] = f[val] || 0;
    f[val]++;
  });

  return f;
};

const getDuplicated = (arr) => {
  const f = getFrequencies(arr);
  return Object.keys(f).filter((k) => f[k] > 1);
};

const RemoveMissingFilters = ({ uiFilters, resource }) => {
  const { filterValues, setFilters } = useListContext();

  const storedFilterKeysQ = Object.keys(filterValues).map((storedFilterKey) =>
    qualifyField(storedFilterKey, resource)
  );
  const uiFiltersKeysQ = uiFilters.map((uiFilter) =>
    qualifyField(uiFilter.props.source, resource)
  );

  const missing = storedFilterKeysQ.filter(
    (storedFilterKeyQ) => !uiFiltersKeysQ.includes(storedFilterKeyQ)
  );

  const duplicatedQ = getDuplicated(storedFilterKeysQ);
  const duplicated = duplicatedQ.concat(
    duplicatedQ.map((k) => unqualifyField(k))
  );

  const incorrect = missing.concat(duplicated);

  if (incorrect.length) {
    debug_log(
      `RemoveMissingFilters: Removing buggy stored filters: ${incorrect.join(
        ", "
      )}.`
    );
    const sanitizedFilters = omit(filterValues, incorrect);
    setFilters(sanitizedFilters);
  }

  return null;
};

const camelizeKeys = (o) => {
  if (!o) {
    return o;
  }

  const camel = {};

  Object.keys(o).forEach((key) => {
    camel[key] = o[key];
    if (key.includes("_")) {
      camel[camelCase(key)] = o[key];
    }
  });

  return camel;
};

const ListActions = function (props) {
  return (
    <TopToolbar>
      {props.supportDatagridConfigurable && (
        <SelectColumnsButton preferenceKey={props.preferenceKey} />
      )}
      <FilterButton />
      {props.resourceData.hasCreate && (
        <CreateButton state={{ record: props.filter }} />
      )}
      <ExportButton />
    </TopToolbar>
  );
};

const complexLabels = (children) =>
  children.filter(
    (child) =>
      child &&
      child.props &&
      child.props.label &&
      typeof child.props.label !== "string"
  );

const getRowButtons = ({ resourceData, showOnClick }) => {
  const buttons = [];

  if (resourceData.hasShow && !showOnClick) {
    buttons.push(<ShowButton key={"show_button"} />);
  }

  if (resourceData.hasEdit) {
    buttons.push(<EditButton key={"edit_button"} />);
  }

  return buttons;
};

export const MyList = (props) => {
  const resource = props.resource;
  const resourceData = useResourceDefinition({ resource });
  const notify = useNotify();

  //https://github.com/marmelab/react-admin/blob/master/CHANGELOG.md#v4104
  const supportDatagridConfigurable = !complexLabels(props.children).length;

  const Grid = supportDatagridConfigurable ? DatagridConfigurable : Datagrid;

  debug_log(`<<<<<<<<<<<< List for '${resource}' <<< start`);

  let filters = getUniqueUIFilters(props.filters, props.filter);
  filters = qualifyUIFilter(filters, resource);

  const filterDefaultValues = objectMap({
    obj: props.filterDefaultValues || {},
    keyCallback: (key) => qualifyField(key, resource),
  });
  const filter = qualifyFilter(props.filter, resource);
  const storeKey = getStoreKey({ resource });
  const preferenceKey = supportDatagridConfigurable ? storeKey : null;
  const [storedData] = useStore(storeKey);
  const showOnClick = Settings.getValue("showOnClick");

  const dataGridProps = Object.assign(
    {},
    { rowClick: resourceData.hasShow && showOnClick ? "show" : null },
    preferenceKey ? { preferenceKey } : {},
    props.dataGridProps || {}
  );

  const processedProps = Object.assign({}, props, {
    filter,
    filters,
    filterDefaultValues,
    storeKey,
    dataGridProps: null,
    perPage: packageConfig.listPageSize,
    empty: (
      <Empty buttonProps={{ state: { record: camelizeKeys(props.filter) } }} />
    ),
    queryOptions: { meta: { notify } },
  });

  const actionsProps = {
    preferenceKey,
    resourceData,
    supportDatagridConfigurable,
    filter: camelizeKeys(props.filter),
  };

  const actions = <ListActions {...actionsProps} />;

  debug_log(`>>>>>>>>>>>> List for '${resource}' >>> end`);

  return (
    <>
      <List {...processedProps} actions={actions}>
        <Grid {...dataGridProps}>
          {props.children}
          {getRowButtons({ resourceData, showOnClick })}
        </Grid>
        <RemoveMissingFilters uiFilters={filters} resource={resource} />
      </List>
      <Debug>
        <Card>
          <CardContent>
            <h3>Fixed filter</h3>
            <JsonStyled data={filter} />
            <h3>List setup</h3>
            <JsonStyled
              data={{
                resource,
                resourceData,
                storeKey,
                storedData,
                preferenceKey,
                supportDatagridConfigurable,
                showOnClick,
                filterDefaultValues,
              }}
            />
          </CardContent>
        </Card>
      </Debug>
    </>
  );
};

MyList.propTypes = {
  resource: PropTypes.string.isRequired,
};
