import { DateField, NumberInput, ReferenceField, TextField } from "react-admin";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import { MyList } from "../ra-lb-tools/components/myList";
import { LockerField, LockerInput } from "../components/referenceFields";
import { TowerInput } from "./tower/towers";

const filters = [
  <NumberInput source="id" alwaysOn key={1} />,
  <LockerInput source="locker_id" alwaysOn key={2} />,
  <TowerInput source="tower_id" alwaysOn key={3} />,
  <NumberInput source="packet_type" alwaysOn key={4} />,
];

export default function EventsList(props) {
  const resource = "events";

  return (
    <MyList
      {...props}
      resource={resource}
      dataGridProps={{ bulkActionButtons: false }}
      filters={filters}
    >
      <TextField source="id" />
      <TextField source="packetTypeName" sortable={false} />
      <JsonField source="eventData" />
      <DateField source="received" showTime={true} />
      <TextField source="description" sortable={false} />
      <LockerField
        source="eventData.locker_id"
        label="Locker"
        sortable={false}
      />
      <ReferenceField source="towerId" reference="towers" link="show">
        <TextField source="name" />
      </ReferenceField>
    </MyList>
  );
}
