import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";

const Dashboard = () => (
  <Card>
    <Title title="Welcome to Harbor Admin" />
    <CardContent>Nothing here yet...</CardContent>
  </Card>
);

export default Dashboard;
