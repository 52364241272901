import { EnvSettings } from "../views/envSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import { Status } from "../views/status";
import { CurrentUser } from "../views/currentUser";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppSettings } from "../views/appSettings";
import { DebugSettings } from "../views/debugSettings";

export const useCustomRoutes = () => [
  {
    path: "/app-settings",
    component: <AppSettings />,
    routeProps: {},
    onMenu: true,
    label: "Settings",
    menuProps: {
      leftIcon: <SettingsIcon />,
    },
    options: {
      sortOrder: 100,
    },
  },
  {
    path: "/debug-settings",
    component: <DebugSettings />,
    routeProps: {},
    onMenu: true,
    label: "Debug Settings",
    menuProps: {
      leftIcon: <SettingsIcon />,
    },
    options: {
      debugOnly: true,
      group: "debug",
    },
  },
  {
    path: "/env-settings",
    component: <EnvSettings />,
    routeProps: {},
    onMenu: true,
    label: "Env Setups",
    menuProps: {
      leftIcon: <SettingsIcon />,
    },
    options: {
      debugOnly: true,
      group: "debug",
    },
  },
  {
    path: "/status",
    component: <Status />,
    routeProps: {},
    onMenu: true,
    menuProps: {
      leftIcon: <SettingsIcon />,
    },
    options: {
      debugOnly: true,
      group: "debug",
    },
  },
  {
    path: "/current-user",
    component: <CurrentUser />,
    routeProps: {},
    onMenu: true,
    menuProps: {
      leftIcon: <AccountCircleIcon />,
    },
    options: {
      debugOnly: true,
      group: "debug",
    },
  },
];
