import { Layout, AppBar, ToggleThemeButton, defaultTheme } from "react-admin";
import { createTheme, Box, Typography } from "@mui/material";
import { blue, grey, lime, yellow, orange, red } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { getEnvStyling } from "../ra-lb-tools/utils/tools";
import { MyMenu } from "../ra-lb-tools/components/myMenu";

const RaLabeled = {
  styleOverrides: {
    root: {
      marginBottom: ".8em",
      "& .RaLabeled-label": {
        color: "#1976d2",
        fontWeight: "bolder",
        fontSize: "1.25em",
      },
      "& .MuiTypography-body2": {
        fontSize: "1em",
      },
    },
  },
};

export const jiTheme = createTheme({
  ...defaultTheme,
  palette: {
    // type: 'dark',
    primary: {
      main: blue[700],
    },
    secondary: {
      main: blue[900],
    },
    background: {
      paper: grey[100],
      default: grey[200],
    },
    specialColors: {
      testing: alpha(orange[600], 0.3),
      oos: alpha(red[600], 0.3),
      highlight: alpha(yellow[100], 0.7),
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    ...defaultTheme.components,
    RaLabeled,
  },
});

const darkTheme = createTheme({
  ...defaultTheme,
  palette: {
    mode: "dark",
    primary: {
      main: yellow[500],
    },
    secondary: {
      main: lime[500],
    },
    background: {
      default: "#232323",
      paper: "#272626",
    },
    specialColors: {
      testing: alpha(orange[200], 0.2),
      oos: alpha(red[200], 0.2),
    },
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    ...defaultTheme.components,
    RaLabeled,
  },
});

const MyAppBar = (props) => {
  const env_styling = getEnvStyling();
  const env_label = env_styling.label && (
    <span style={{ textTransform: "uppercase" }}> ({env_styling.label})</span>
  );
  const env_sx = env_styling.barSx;

  return (
    <AppBar {...props} sx={env_sx}>
      <Box flex="1">
        <Typography
          variant="h6"
          id="react-admin-title"
          sx={{ display: "inline-block" }}
        ></Typography>
        {env_label}
      </Box>
      <ToggleThemeButton lightTheme={jiTheme} darkTheme={darkTheme} />
    </AppBar>
  );
};

const MyLayout = (props) => (
  <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
);

export default MyLayout;
