import { Box } from "@mui/material";
import ReactJson from "react-json-view";
import { CopyToClipboardButton } from "./copyToClipboardButton";

const jsonLines = (data) =>
  JSON.stringify(data, null, 2)
    .split("\n")
    .filter((v) => v.trim().length > 1).length;

export const JsonStyled = (props) => {
  if (!props.data) {
    return null;
  }

  if (
    !props.collapsed &&
    props.maxLines &&
    props.maxLines < jsonLines(props.data)
  ) {
    props = Object.assign({}, props, { collapsed: true });
  }

  const style = {
    maxHeight: "50vh",
    overflowY: "auto",
    position: "relative",
  };

  const buttonStyle = {
    position: "absolute",
    right: "0",
    top: "10px",
    zIndex: "2",
  };

  return (
    <Box style={style}>
      <CopyToClipboardButton data={props.data} sx={buttonStyle} />
      <ReactJson src={props.data} {...props} />
    </Box>
  );
};
JsonStyled.defaultProps = {
  name: null,
  collapsed: false,
  enableClipboard: false,
  displayDataTypes: false,
  theme: "google",
  style: { padding: "1em", borderRadius: "25px" },
};
