import { FunctionField, ReferenceField, ReferenceInput } from "react-admin";
import AutocompleteInputFluid from "./autocompleteInputFluid";
import { Settings } from "../utils/settings";

const recordLabel = (record, props) => {
  const primaryLabel = record[props.primaryField];
  const prefix =
    props.secondaryField && props.secondaryField === "id" ? "#" : "";
  const secondaryLabel = props.secondaryField
    ? " (" + prefix + record[props.secondaryField] + ")"
    : "";

  return primaryLabel + secondaryLabel;
};

const defaults = {
  primaryField: "name",
  secondaryField: Settings.debugging() ? "id" : "",
};

export const MyReferenceInput = (props) => {
  const filter = (searchText) => ({ [props.primaryField]: `%${searchText}` });
  const label = (choice) => recordLabel(choice, props);

  return (
    <ReferenceInput {...props}>
      <AutocompleteInputFluid
        filterToQuery={filter}
        optionText={label}
        optionValue="id"
        label={props.label}
        slack={props.slack}
        disabled={props.disabled}
      />
    </ReferenceInput>
  );
};

MyReferenceInput.defaultProps = defaults;

export const MyReferenceField = (props) => {
  return (
    <ReferenceField {...props}>
      <FunctionField render={(record) => recordLabel(record, props)} />
    </ReferenceField>
  );
};

MyReferenceField.defaultProps = { ...defaults, link: "show" };
