import {
  TextField,
  SimpleShowLayout,
  NumberField,
  TabbedShowLayout,
} from "react-admin";
import { useParams } from "react-router-dom";
import { SpecTable } from "./LockerSpec";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { TowerList } from "../tower/towers";
import { TowerRenderColumn } from "./TowerRender";
import { Row } from "../../ra-lb-tools/components/row";
import { Column } from "../../ra-lb-tools/components/column";
import { useState } from "react";

export const TowerModelShow = () => {
  const id = parseInt(useParams()["id"]);

  const [highlight, setHighligth] = useState([]);
  const [renderAlwaysVisible, setRenderAlwaysVisible] = useState(true);
  const [rowRef, setRowRef] = useState({});

  const addRowRef = (key, ref) =>
    setRowRef((rowRef) => {
      rowRef[key] = ref;
      return rowRef;
    });

  const scrollToRow = (physicalId) =>
    rowRef[physicalId]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Row>
            <Column>
              <Row>
                <SimpleShowLayout>
                  <NumberField source="id" />
                  <TextField source="name" />
                  <TextField source="nameStrategy" />
                  <TextField source="description" />
                  <NumberField source="numColumns" />
                </SimpleShowLayout>
                <SimpleShowLayout>
                  <TextField source="lockerCount" />
                  <TextField source="lowLockerCount" />
                  <TextField source="towerCount" />
                </SimpleShowLayout>
                {!renderAlwaysVisible && (
                  <TowerRenderColumn
                    {...{
                      highlight,
                      setHighligth,
                      renderAlwaysVisible,
                      setRenderAlwaysVisible,
                      onClick: scrollToRow,
                    }}
                  />
                )}
              </Row>
              <Row>
                <SimpleShowLayout>
                  <SpecTable
                    source="lockersSpec"
                    highlight={highlight}
                    onMouseEnter={(locker) => setHighligth([locker.physicalId])}
                    onMouseLeave={() => setHighligth([])}
                    setRowRef={addRowRef}
                  />
                </SimpleShowLayout>
              </Row>
            </Column>
            {renderAlwaysVisible && (
              <TowerRenderColumn
                {...{
                  highlight,
                  setHighligth,
                  renderAlwaysVisible,
                  setRenderAlwaysVisible,
                  onClick: scrollToRow,
                }}
              />
            )}
          </Row>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Towers" path="towers">
          <TowerList filter={{ tower_model_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retired Towers" path="towers-retired">
          <TowerList
            filter={{ tower_model_id: id, status_id: 4 }}
            filters={null}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};
