import {
  TextField,
  TextInput,
  Show,
  SimpleShowLayout,
  BooleanInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  PasswordInput,
} from "react-admin";
import RegenerateSecretButton from "../components/buttonRenerateSecret";
import CopyTextField from "../components/copyTextField";
import CopySecretField from "../components/copySecretField";
import ComputerIcon from "@mui/icons-material/Computer";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { MyBooleanField as BooleanField } from "../components/myBooleanField";

export const ClientIcon = ComputerIcon;

const clientsFilters = [<TextInput source="name" alwaysOn key={1} />];

export const clientsList = (props) => (
  <MyList {...props} filters={clientsFilters} resource="clients">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="clientId" />
    <BooleanField source="enabled" />
  </MyList>
);

export const clientsView = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="enabled" />
      <CopyTextField source="clientId" />
      <CopySecretField source="secret" label="secret" />
    </SimpleShowLayout>
  </Show>
);

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const clientsEdit = () => {
  return (
    <Edit formProps={{ toolbar: <UserEditToolbar /> }}>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <BooleanInput source="enabled" />
      <TextInput disabled source="clientId" />
      <PasswordInput disabled source="secret" />
      <RegenerateSecretButton />
    </Edit>
  );
};

export const clientsCreate = () => {
  return (
    <Create redirect="show">
      <TextInput source="clientId" />
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <ReferenceInput source="ownerId" reference="users" allowEmpty>
        <AutocompleteInputFluid
          filterToQuery={(searchText) => ({ email: `%${searchText}` })}
          optionText="email"
          optionValue="id"
        />
      </ReferenceInput>
    </Create>
  );
};
