import { Stage, Layer, Rect, Text, Path } from "react-konva";
import { useGetList, useRecordContext } from "react-admin";
import groupBy from "lodash/groupBy";
import get from "lodash/get";
import { debug_log } from "../../ra-lb-tools/utils/debugLog";
import { getType, lockerName } from "./lockersHelpers";
import { FieldInput } from "../../ra-lb-tools/components/unsourcedInputs";
import { Box } from "@mui/material";
import { Column } from "../../ra-lb-tools/components/column";
import { DebugDotJson } from "../../ra-lb-tools/components/debugDotJson";
import range from "lodash/range";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import inflection from "inflection";
import ReactDOMServer from "react-dom/server";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import WarningIcon from "@mui/icons-material/Warning";

const shieldThreshold = 24;
const getNameLabel = (name) =>
  name
    .replace(/extra/gi, "xtra")
    .split(" ")
    .map((str) => str.toUpperCase()[0])
    .join("");
const getShield = (pId) => Math.ceil(pId / shieldThreshold);
const getShieldLabel = (pId) => getShield(pId) + "-";
const getPId = (pId, withShield) =>
  withShield ? ((pId - 1) % shieldThreshold) + 1 : pId;
const getPIdLabel = (pId, withShield) =>
  "[#" +
  (withShield ? getShieldLabel(pId) : "") +
  getPId(pId, withShield) +
  "]";

const KonvaMuiIcon = (props) => {
  const svgString = ReactDOMServer.renderToStaticMarkup(props.MuiIcon);

  return <Path {...props} data={svgString} />;
};

const LockerRender = ({
  spec,
  types,
  baseSize,
  index,
  highlight,
  onMouseEnter,
  onMouseLeave,
  lockerNameGenerator,
  onClick,
  options,
}) => {
  highlight = highlight || [];

  const lockerType = getType(spec, types);
  if (!lockerType) {
    debug_log(`locker type not found, phys id: ${spec.physicalId}`);
    return null;
  }

  const props = {
    width: (spec.width || lockerType.relWidth) * baseSize.width,
    heigth: (spec.heigth || lockerType.relHeight) * baseSize.heigth,
    x: (spec.x || spec.colPosition - 1 + spec.offsetPosition) * baseSize.width,
    y: (spec.y || spec.rowPosition - 1) * baseSize.heigth,
  };

  if (Object.values(props).some((value) => isNaN(value))) {
    debug_log(`LockerRender: NaN value found phys id: ${spec.physicalId}`);
    return null;
  }

  const name =
    spec.name || (lockerNameGenerator ? lockerNameGenerator.next().value : "");

  const label =
    spec.label ||
    [
      options.type ? getNameLabel(lockerType.name) : "",
      options.name ? name : "",
      options.port ? getPIdLabel(spec.physicalId, options.shield) : "",
    ].join(" ");

  let fillByType = "blue";
  switch (spec.accessModeId) {
    case 1:
      fillByType = "green";
      break;
    case 2:
      fillByType = "orange";
      break;
    case 3:
      fillByType = "darkorchid";
      break;

    default:
      break;
  }

  const fill = spec.fill || fillByType;

  let style = {
    opacity: 0.3,
    strokeWidth: 1,
    textColor: "black",
    textColorLow: "blue",
  };

  if (highlight.includes(spec.physicalId)) {
    style = {
      opacity: 1,
      strokeWidth: 3,
      textColor: "white",
      textColorLow: "white",
    };
  } else if (
    options.highlight_shield &&
    options.highlight_shield != getShield(spec.physicalId)
  ) {
    style = {
      opacity: 0.1,
      textOpacity: 0.1,
    };
  }

  const textXOffset = -3 * label.length;
  const textYOffset = -5;

  return [
    <Rect
      key={index}
      fill={fill}
      opacity={style.opacity}
      stroke={"black"}
      strokeWidth={style.strokeWidth}
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.heigth}
    />,
    <Text
      key={index + "k"}
      text={label}
      x={props.x + props.width / 2 + textXOffset}
      y={props.y + props.heigth / 2 + textYOffset}
      fill={style.textColor}
      opacity={style.textOpacity ? style.textOpacity : null}
    />,
    spec.isLowLocker ? (
      <KonvaMuiIcon
        key={index + "low"}
        x={props.x + props.width - 25}
        y={props.y + props.heigth - 25}
        fill={style.textColorLow}
        opacity={style.textOpacity ? style.textOpacity : null}
        MuiIcon={<AccessibilityIcon />}
      />
    ) : null,
    <Rect
      key={index + "event"}
      fill={"transparent"}
      stroke={"transparent"}
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.heigth}
      onMouseEnter={() => onMouseEnter && onMouseEnter(spec)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(spec)}
      onClick={() => onClick && onClick(spec.physicalId)}
    />,
  ];
};

const getRowsByColumn = (specs, types) => {
  const byColumn = groupBy(
    specs.filter((v) => v.type),
    (el) => el.colPosition
  );
  const rowsByColumn = {};

  for (const col in byColumn) {
    const column = byColumn[col];
    const last = column[column.length - 1];
    const lastType = getType(last, types);
    const lastHeigth = lastType ? lastType.relHeight : 0;

    rowsByColumn[col] = last.rowPosition + lastHeigth - 1;
  }

  return rowsByColumn;
};

const getMaxRows = (specs, types) => {
  return Math.max(...Object.values(getRowsByColumn(specs, types)));
};

const LockersRender = ({
  numColumns,
  lockers,
  types,
  canvasSize,
  lockerNameGenerator,
  options,
  ...rest
}) => {
  if (!types) return null;

  const maxCols = numColumns;
  const maxRows = getMaxRows(lockers, types);

  const baseSize = {
    width: canvasSize.width / maxCols,
    heigth: canvasSize.heigth / maxRows,
  };

  let rowsByColumn = Object.assign(
    {},
    ...range(1, parseInt(numColumns) + 1).map((k) => ({ [k]: 0 }))
  );
  rowsByColumn = Object.assign(
    {},
    rowsByColumn,
    getRowsByColumn(lockers, types)
  );
  const freeSpacePlaceholders = [];
  for (const col in rowsByColumn) {
    const free = maxRows - rowsByColumn[col];
    if (free) {
      freeSpacePlaceholders.push({
        colPosition: parseInt(col),
        rowPosition: rowsByColumn[col] + 1,
        offsetPosition: 0,
        width: 1,
        heigth: free,
        lockerType: 1,
        label: "Available",
        fill: "grey",
        type: 1,
      });
    }
  }

  const lockersToRender = lockers.concat(freeSpacePlaceholders);

  let renderedLockers = [];
  lockersToRender.forEach((spec, index) => {
    renderedLockers = renderedLockers.concat(
      <LockerRender
        key={index}
        {...{
          spec,
          types,
          baseSize,
          index,
          lockerNameGenerator,
          options,
          ...rest,
        }}
      />
    );
  });

  return renderedLockers;
};

const OptionBar = (props) => {
  const showOptions = [];

  const sx = props.columnStyle ? {} : { display: "flex", flexWrap: "wrap" };

  Object.entries(props.options).map(([name, value]) => {
    showOptions.push(
      FieldInput({
        name,
        value,
        label: inflection.humanize(name),
        setter: (value) => props.onChange && props.onChange({ [name]: value }),
      })
    );
  });

  return (
    <Box className="OptionBar" sx={sx}>
      {showOptions}
    </Box>
  );
};

const ToolBox = ({
  alwaysVisible,
  onAlwaysVisibleChange,
  options,
  setOptions,
}) => {
  const [unfolded, setUnfolded] = useState(false);

  const sx = {
    border: "1px solid black",
  };

  const titleSx = {
    textAlign: "center",
    color: "red",
    fontSize: "1.2em",
  };

  const FoldSwitch = FieldInput({
    value: unfolded,
    setter: setUnfolded,
    name: "folded",
    label: "Diagram Tools",
    sx: titleSx,
  });

  return (
    <Box className="ToolBox" sx={sx}>
      <Box>{FoldSwitch}</Box>
      {unfolded && (
        <Box>
          {FieldInput({
            value: !!alwaysVisible,
            setter: (value) =>
              onAlwaysVisibleChange && onAlwaysVisibleChange(value),
            name: "alwaysVisible",
            label: "Always visible",
          })}
          <OptionBar
            options={options}
            onChange={(entry) =>
              setOptions((_oldOptions) => ({ ..._oldOptions, ...entry }))
            }
          />
        </Box>
      )}
    </Box>
  );
};

export const TowerRender = ({
  lockers,
  numColumns,
  canvasSize,
  alwaysVisible,
  onAlwaysVisibleChange,
  sx,
  nameStrategy,
  ...rest
}) => {
  const FormContext = useFormContext();
  const getFormValues = FormContext && FormContext.getValues;
  const record = useRecordContext();
  lockers = get(lockers, "length") ? lockers : get(record, "lockersSpec", []);
  const lowLockers = lockers.filter((locker) => locker.isLowLocker);

  const [options, setOptions] = useState({
    name: true,
    port: true,
    shield: lockers.length > shieldThreshold ? true : false,
    type: false,
    highlight_shield: 0,
  });

  numColumns = numColumns || get(record, "numColumns", 1);
  nameStrategy =
    nameStrategy ||
    (getFormValues && getFormValues("nameStrategy")) ||
    get(record, "nameStrategy");

  const { data: types } = useGetList("locker-types");

  const totalSize = window.innerWidth / 3;
  canvasSize = canvasSize || {
    width: totalSize,
    heigth: totalSize,
  };

  const lockerNameGenerator =
    nameStrategy == "numeric"
      ? lockerName()
      : nameStrategy == "alphanumeric"
      ? lockerName("A-")
      : null;

  const lockerCountSx = {
    display: "flex",
    padding: "5px 0",
  };

  return (
    <Box className="TowerRender" sx={sx} width={canvasSize.width}>
      <ToolBox
        {...{ alwaysVisible, onAlwaysVisibleChange, options, setOptions }}
      />
      <Box sx={lockerCountSx} className={"InfoBox"}>
        Lockers: {lockers.length} ({lowLockers.length} low{" "}
        {!lowLockers.length && <WarningIcon style={{ color: "#e4a11b" }} />})
      </Box>
      <Stage
        width={canvasSize.width}
        height={canvasSize.heigth}
        style={{ border: "1px solid black" }}
      >
        <Layer>
          <LockersRender
            {...{
              numColumns,
              lockers,
              types,
              canvasSize,
              lockerNameGenerator,
              options,
              ...rest,
            }}
          />
        </Layer>
      </Stage>
      <DebugDotJson data={lockers} />
    </Box>
  );
};

export const TowerRenderColumn = ({
  lockers,
  numColumns,
  highlight,
  setHighligth,
  renderAlwaysVisible,
  setRenderAlwaysVisible,
  nameStrategy,
  onClick,
  ...rest
}) => {
  const totalSize = window.innerWidth / 4;
  const canvasSize = {
    width: totalSize,
    heigth: totalSize,
  };

  const sx = renderAlwaysVisible
    ? {
        position: "fixed",
        right: "20px",
        zIndex: 1,
      }
    : {};

  return (
    <Column className="TowerRenderColumn" minWidth={canvasSize.width} {...rest}>
      <TowerRender
        lockers={lockers}
        numColumns={numColumns}
        canvasSize={canvasSize}
        highlight={highlight}
        onMouseEnter={(locker) => setHighligth([locker.physicalId])}
        onMouseLeave={() => setHighligth([])}
        alwaysVisible={renderAlwaysVisible}
        onAlwaysVisibleChange={(value) => setRenderAlwaysVisible(value)}
        sx={sx}
        nameStrategy={nameStrategy}
        onClick={onClick}
      />
    </Column>
  );
};
