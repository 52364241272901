import { Button, useTheme } from "react-admin";

export const ButtonStrong = (props) => {
  const [theme] = useTheme();
  const is_dark = theme.palette.mode === "dark";
  const style = {
    backgroundColor: is_dark ? "Blue" : "Beige",
  };

  return <Button {...props} sx={style} />;
};
