import { useResourceDefinitions } from "ra-core";
import { Menu, ResourceMenuItem } from "react-admin";
import get from "lodash/get";
import pickBy from "lodash/pickBy";
import sortBy from "lodash/sortBy";
import { Box } from "@mui/material";
import { Settings } from "./settings";
import { customRouteMenu, debugInfo } from "../theme/styles";
import { AccordionMenu } from "../components/accordionMenu";
import { useCustomRoutes } from "../config/customRoutes";
import { getConfig } from "../config/packageSetup";

const isAvailableForMenu = (resource) =>
  (resource.hasList || resource.onMenu) &&
  (Settings.debugging() || !get(resource, "options.debugOnly"));

const getSx = (options) =>
  Object.assign(
    {},
    customRouteMenu,
    options.sx || {},
    options.debugOnly ? debugInfo : {}
  );

const renderResourceMenu = (resourceName, options) => (
  <Box key={resourceName} sx={getSx(options)}>
    <ResourceMenuItem name={resourceName} />
  </Box>
);

const renderCustomMenu = (route) => (
  <Menu.Item
    key={route.path}
    to={route.path}
    primaryText={route.label || route.path.replaceAll(/[^\w\d\s]/g, " ").trim()}
    {...route.menuProps}
    sx={getSx(route.options || {})}
  />
);

const getGrouped = () => {
  const keys = getConfig().menuGroups || [];

  keys.includes("") || keys.unshift("");
  keys.includes("debug") || keys.push("debug");

  const grouped = keys.reduce((acc, key) => {
    acc[key] = null;
    return acc;
  }, {});

  return grouped;
};

/**
 * Based on
 * https://github.com/marmelab/react-admin/blob/0e4c7ca1187649bbe63fd04321c20b4de0ee488b/packages/ra-ui-materialui/src/layout/ResourceMenuItems.tsx
 *
 * Edit: there is almost nothing left from the original code
 */
export const ResourceMenuItems = () => {
  const resources = Object.assign(
    {},
    useResourceDefinitions(),
    useCustomRoutes()
  );
  const available = pickBy(resources, isAvailableForMenu);
  const sorted = sortBy(available, (v) => get(v, "options.sortOrder", 0));

  const grouped = getGrouped();

  sorted.forEach((resource) => {
    const options = resource.options || {};
    const group = options.group || "";

    grouped[group] = grouped[group] || [];
    const menu = resource.hasList
      ? renderResourceMenu(resource.name, options)
      : renderCustomMenu(resource);

    grouped[group].push(menu);
  });

  let menus = [];
  Object.keys(grouped).forEach((groupName) => {
    const group = grouped[groupName];
    if (!(group && group.length)) {
      return;
    }

    if (!groupName) {
      menus = menus.concat(group);
    } else {
      menus.push(
        <AccordionMenu key={groupName} title={groupName}>
          <Box>{group}</Box>
        </AccordionMenu>
      );
    }
  });

  return <>{menus}</>;
};
