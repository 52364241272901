import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
import { JsonStyled } from "../components/jsonStyled";

export const EnvSettings = () => {
  return (
    <Card>
      <Title title="Settings" />
      <CardContent>
        <JsonStyled data={window.ENV} />
      </CardContent>
    </Card>
  );
};
