import {
  TextField,
  TabbedShowLayout,
  DateField,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  SimpleList,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { PlanSubscriptionList } from "./plan_subsciptions";
import { useParams } from "react-router-dom";

export const PlanIcon = BusinessIcon;

export const PlanList = (props) => (
  <MyList {...props} resource="plans">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <ArrayField source="prices">
      <SimpleList primaryText={(record) => record} linkType={false} />
    </ArrayField>
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);
export const PlanShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="description" />
              <ArrayField source="prices">
                <SimpleList primaryText={(record) => record} linkType={false} />
              </ArrayField>
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Subscriptions" path="Subscriptions">
          <PlanSubscriptionList filter={{ plan_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const PlanCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <ArrayInput source="prices" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>{" "}
    </Create>
  );
};

export const PlanInput = (props) => {
  return <MyReferenceInput source="planId" reference="plans" {...props} />;
};

export const PlanField = (props) => {
  return <MyReferenceField source="planId" reference="plans" {...props} />;
};
