import { Stack } from "@mui/material";

export const Row = (props) => (
  <Stack
    spacing={4}
    direction="row"
    sx={{ justifyContent: "space-between" }}
    className="Row"
  >
    {props.children}
  </Stack>
);
