import {
  TextField,
  TextInput,
  TabbedShowLayout,
  BooleanInput,
  required,
  DateField,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../../ra-lb-tools/components/myMutator";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { UserField, UserInput } from "../users";
import { PlanSubscriptionList } from "./plan_subsciptions";
import { useParams } from "react-router-dom";
import { MyBooleanField as BooleanField } from "../../components/myBooleanField";

export const OrganizationIcon = BusinessIcon;

export const OrganizationList = (props) => (
  <MyList {...props} resource="organizations">
    <TextField source="id" />
    <TextField source="name" />
    <BooleanField source="active" />
    <UserField source="accountOwnerId" />
    <UserField source="serviceAccountId" />
    <TextField source="phone" />
    <TextField source="billingAddress" />
    <TextField source="shippingAddress" />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);

export const OrganizationEdit = (props) => {
  return (
    <Edit {...props}>
      <BooleanInput source="active" />
      <TextInput source="name" validate={required()} />
      <UserInput source="accountOwnerId" validate={required()} />
      <UserInput source="serviceAccountId" />
      <TextInput source="phone" />
      <TextInput source="billingAddress" />
      <TextInput source="shippingAddress" />
    </Edit>
  );
};

export const OrganizationCreate = (props) => {
  return (
    <Create {...props}>
      <BooleanInput source="active" />
      <TextInput source="name" validate={required()} />
      <UserInput source="accountOwnerId" validate={required()} />
      <UserInput source="serviceAccountId" />
      <TextInput source="phone" />
      <TextInput source="billingAddress" />
      <TextInput source="shippingAddress" />
    </Create>
  );
};

export const OrganizationShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <BooleanField source="active" />
              <UserField source="accountOwnerId" />
              <UserField source="serviceAccountId" />
              <TextField source="phone" />
              <TextField source="billingAddress" />
              <TextField source="shippingAddress" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Subscriptions" path="Subscriptions">
          <PlanSubscriptionList filter={{ organization_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const OrganizationInput = (props) => {
  return (
    <MyReferenceInput
      source="organizationId"
      reference="organizations"
      {...props}
    />
  );
};

export const OrganizationField = (props) => {
  return (
    <MyReferenceField
      source="organizationId"
      reference="organizations"
      {...props}
    />
  );
};
