import { useParams } from "react-router-dom";
import {
  TextField,
  ReferenceField,
  NumberField,
  SelectField,
  TabbedShowLayout,
  DateField,
  useNotify,
} from "react-admin";
import { statusChoices, accessModeChoices } from "./base";
import EventsList from "../events";
import CommandsTable from "../commands";

import { StatusHeader } from "./components";

import ResetButton from "./buttons";
import { TowerHistoryList } from "../towerHistory";
import { LockerList } from "../lockers";
import { Card, CardContent, CardHeader } from "@mui/material";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord.js";
import { LocationField } from "../locations/components";
import { KeyList } from "../keys";
import { ReservationList } from "../reservations";
import { openLockerTokenList as OpenLockerTokenList } from "../openLockerTokens";
import get from "lodash/get";
import { debug_log } from "../../ra-lb-tools/utils/debugLog.js";
import { Store } from "../../ra-lb-tools/utils/store.js";
import { ClickToBuffer } from "../../ra-lb-tools/components/clickToBuffer.js";
import { SystemField } from "../systems";
import { useState } from "react";
import { shakeAnimation } from "../../ra-lb-tools/theme/styles.js";
import { LockerUsageList } from "../lockerUsage";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn.js";
import { Row } from "../../ra-lb-tools/components/row.js";
import { MyBooleanField as BooleanField } from "../../components/myBooleanField";

const getTabCode = (e) => {
  const label = get(e, "target.childNodes[0].data", "");
  if (!label) {
    return;
  }

  const code = label.substring(0, 1).toLowerCase();
  return code;
};

const commands = {
  rockkk: () => {
    console.log("Switching debug mode");
    Store.flip("debug");
    window.location.reload();
  },
};

function endsWithStartOf(str1, str2, minMatch = 0) {
  const minOverlap = Math.min(str1.length, str2.length);

  for (let i = minOverlap; i > minMatch; i--) {
    if (str1.endsWith(str2.slice(0, i))) {
      return str2.slice(0, i);
    }
  }
  return false;
}

function endsWithStartOfAny(haystack, needles, minMatch = 0) {
  for (let index = 0; index < needles.length; index++) {
    const needle = needles[index];
    const intersection = endsWithStartOf(haystack, needle, minMatch);
    if (intersection) {
      return intersection;
    }
  }

  return false;
}

const codeSent = (buffer, callback, notify) => {
  let normalizedBuffer = buffer.toLowerCase();
  const commandList = Object.keys(commands);

  const selectedCommand = commandList.filter((commandKey) =>
    normalizedBuffer.includes(commandKey)
  )[0];

  if (selectedCommand) {
    debug_log(`Execute '${selectedCommand}'`);
    const command = commands[selectedCommand];
    command();
    callback(true);
    return "";
  }

  const partialMatch = endsWithStartOfAny(buffer, commandList, 2);
  if (partialMatch) {
    notify(partialMatch, {
      autoHideDuration: 1800,
      multiLine: true,
      type: "success",
    });
  }

  callback(false);
  return buffer;
};

export const TowerShow = () => {
  const { id } = useParams();
  const classes = shakeAnimation();
  const [shake, setShake] = useState(false);
  const notify = useNotify();

  return (
    <TitledShow className={shake ? classes.shake : null}>
      <ClickToBuffer
        parser={getTabCode}
        processor={(buffer, callback) => codeSent(buffer, callback, notify)}
        callback={setShake}
      >
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Summary">
            <Card>
              <CardContent>
                <Row>
                  <LabeledColumn>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="pinCode" />
                    <TextField source="description" />
                    <BooleanField source="hasKeypad" />
                    <ReferenceField
                      source="towerModelId"
                      reference="tower-models"
                      link="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </LabeledColumn>
                  <LabeledColumn>
                    <SystemField label="System" />
                    <LocationField label="Location" />
                    <TextField source="location.city" label="City" />
                    <TextField source="location.state" label="State" />
                    <SelectField
                      source="statusId"
                      choices={statusChoices}
                      label={<StatusHeader />}
                    />
                    <SelectField
                      source="accessModeId"
                      choices={accessModeChoices}
                    />
                  </LabeledColumn>
                  <LabeledColumn>
                    <NumberField source="lastEvent" />
                    <NumberField source="lastCommand" />
                    <ReferenceField
                      source="ownerId"
                      reference="users"
                      link="show"
                    >
                      <TextField source="email" />
                    </ReferenceField>
                    <TextField source="fwVersion" />
                    <TextField source="deviceSerial" />
                    <TextField source="deviceModel" />
                  </LabeledColumn>
                  <LabeledColumn>
                    <DateField source="lastEventsReset" showTime={true} />
                    <DateField source="lastEventsSync" showTime={true} />
                  </LabeledColumn>
                </Row>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Lockers" sx={{ paddingBottom: 0 }} />
              <CardContent sx={{ paddingTop: 0 }}>
                <LockerList filter={{ towerId: id }} filters={null} />
              </CardContent>
            </Card>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="History" path="history">
            <TowerHistoryList towerId={id} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Events" path="events">
            <EventsList filter={{ towerId: id }} title="Tower Events" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Commands" path="commands">
            <CommandsTable filter={{ towerId: id }} title="Tower Commands" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Keys" path="keys">
            <KeyList filter={{ tower_id: id }} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Reservations" path="reservations">
            <ReservationList filter={{ locker__tower_id: id }} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab
            label="Open Locker Token"
            path="open-locker-tokens"
          >
            <OpenLockerTokenList filter={{ locker__tower_id: id }} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Locker Usage" path="locker-usage">
            <LockerUsageList filter={{ locker__tower_id: id }} />
          </TabbedShowLayout.Tab>
          <ResetButton />
        </TabbedShowLayout>
      </ClickToBuffer>
    </TitledShow>
  );
};
