import { useParams } from "react-router-dom";
import {
  TextField,
  TabbedShowLayout,
  DateField,
  SelectField,
  SelectInput,
  TextInput,
  required,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { LockerTypeField, LockerTypeInput } from "../lockerTypes";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { ProductPriceList } from "./prices";

const UsagePeriodProduct = [
  { id: "minute", name: "Minute" },
  { id: "hour", name: "Hour" },
  { id: "day", name: "Day" },
];

const UsageProductType = [
  { id: "per_transaction", name: "Per transaction" },
  { id: "per_usage_period", name: "Per usage period" },
  { id: "no_metered", name: "No metered" },
];
export const ProductIcon = BusinessIcon;

export const ProductList = (props) => (
  <MyList {...props} resource="products">
    <TextField source="id" />
    <TextField source="name" />
    <LockerTypeField source="lockerTypeId" />
    <TextField source="processorId" />
    <SelectField source="type" choices={UsageProductType} />
    <SelectField source="usagePeriod" choices={UsagePeriodProduct} />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);
export const ProductShow = () => {
  const { id } = useParams();

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <LockerTypeField source="lockerTypeId" />
              <TextField source="processorId" />
              <SelectField source="type" choices={UsageProductType} />
              <SelectField source="usagePeriod" choices={UsagePeriodProduct} />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Prices" path="prices">
          <ProductPriceList productId={id} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const ProductCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" validate={required()} />
      <SelectInput source="type" choices={UsageProductType} />
      <SelectInput source="usagePeriod" choices={UsagePeriodProduct} />
      <LockerTypeInput source="lockerTypeId" />
    </Create>
  );
};

export const ProductInput = (props) => {
  return (
    <MyReferenceInput source="productId" reference="products" {...props} />
  );
};

export const ProductField = (props) => {
  return (
    <MyReferenceField source="productId" reference="products" {...props} />
  );
};
