import { Show, useRecordContext, useResourceContext } from "react-admin";

import { Box } from "@mui/material";

const ResourceLabel = () => {
  const resource = (useResourceContext() || "").slice(0, -1);

  return (
    <Box component="span" sx={{ textTransform: "capitalize" }}>
      {resource}
    </Box>
  );
};

const RecordTitle = (props) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const entityLabel =
    record[props.labelField] ||
    record["name"] ||
    record["email"] ||
    record["fullName"] ||
    "nameless";

  return (
    <Box sx={{ padding: "0 16px" }}>
      <ResourceLabel /> {`'${entityLabel}' (#${record.id})`}
    </Box>
  );
};

const TitledWrapper = (props) => {
  return (
    <Box sx={{ width: "100%" }}>
      <RecordTitle labelField={props.labelField} />
      {props.children}
    </Box>
  );
};

export const TitledShow = (props) => {
  const recordTitle = <RecordTitle labelField={props.labelField} />;

  return (
    <Show title={recordTitle} component={TitledWrapper} {...props}>
      {props.children}
    </Show>
  );
};
