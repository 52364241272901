import {
  TextField,
  SelectField,
  DateField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  useRecordContext,
} from "react-admin";
import { useParams, Link } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import { JsonField } from "../ra-lb-tools/components/jsonField";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyList } from "../ra-lb-tools/components/myList";
import get from "lodash/get";
import { Debug } from "../ra-lb-tools/components/debug";
import { Settings } from "../ra-lb-tools/utils/settings";
import { getEnvStyling } from "../ra-lb-tools/utils/tools";

export const SystemIcon = BusinessIcon;

/**
 * TODO:
 * Check for enhancement
 * Currently this needs to be manually synchronized from
 * hal-backend/app/app/models/tower_history.py::class TowerHistoryType(EnumBase)
 */
export const typeChoices = [
  { id: 1, name: "Status Change" },
  { id: 2, name: "Firmware Change" },
  { id: 3, name: "Device Serial Change" },
  { id: 4, name: "Device Model Change" },
  { id: 5, name: "Events Reset: User Reset" },
  { id: 6, name: "Events Reset: Automatic Reset" },
  { id: 7, name: "Event Sync" },
  { id: 8, name: "Error Event" },
];

const towerHistoryFilters = (typeChoices) => [
  <SelectInput
    label="Type"
    source="history##type"
    choices={typeChoices}
    alwaysOn
    key={"history##type"}
  />,
  <ReferenceInput
    source="history##user_id"
    label="User"
    reference="users"
    alwaysOn
    allowEmpty
    key={"history##user_id"}
  >
    <AutocompleteInputFluid
      label="User"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
];

const logUri = "https://grafana.harborlockers.com/explore";
const getPane = (request_id, from, to, namespace, datasource_uid) => ({
  zvr: {
    datasource: datasource_uid,
    queries: [
      {
        refId: "A",
        expr: `{namespace="${namespace}"} |= \`${request_id}\``,
        queryType: "range",
        datasource: {
          type: "loki",
          uid: datasource_uid,
        },
        editorMode: "builder",
      },
    ],
    range: {
      from: `${from}`,
      to: `${to}`,
    },
    panelsState: {
      logs: {
        visualisationType: "logs",
      },
    },
  },
});

const getLogUri = (requestInfo) => {
  const request_id = requestInfo.id;
  const time = new Date(requestInfo.timestamp + "Z");
  const delta = 30 * 60 * 1000;
  const from = time.getTime() - delta;
  const to = time.getTime() + delta;
  const namespace = getEnvStyling().grafana.namespace;
  const datasource_uid = "P8E80F9AEF21F6940"; //TODO: customize this

  const pane = getPane(request_id, from, to, namespace, datasource_uid);
  const uri = `${logUri}?schemaVersion=1&orgId=1&panes=` + JSON.stringify(pane);
  return uri;
};

const LogLink = () => {
  const record = useRecordContext();
  const requestInfo = get(record, "new.request");

  if (!(requestInfo && requestInfo.id && requestInfo.timestamp)) {
    return null;
  }

  const logUri = getLogUri(requestInfo);

  return (
    <Link to={logUri} target="_blank">
      Log
    </Link>
  );
};

const SummaryField = () => {
  let sx = {
    fontWeight: "bolder",
  };

  const { summary } = useRecordContext();
  if (!summary) return null;

  switch (summary.toLowerCase()) {
    case "success":
      sx.color = "green";
      break;
    case "failed":
      sx.color = "red";
      break;
    case "mixed":
      sx.color = "orange";
      break;
    case "empty":
      sx.color = "blue";
      break;

    default:
      break;
  }

  return <TextField source="summary" sortable={false} sx={sx} />;
};

export const TowerHistoryList = (props) => {
  const title = "Tower History";
  const resource = "history";
  const towerId = props.towerId;

  const filter = { towerId };

  return (
    <MyList
      title={title}
      resource={resource}
      filter={filter}
      filters={towerHistoryFilters(typeChoices)}
      dataGridProps={{
        bulkActionButtons: false,
        rowClick: null,
      }}
      sort={{
        field: "id",
        order: "DESC",
      }}
    >
      <TextField source="id" />
      <TextField source="towerId" />
      <DateField source="timestamp" showTime />
      <SelectField source="type" choices={typeChoices} />
      <SummaryField source="summary" />
      <JsonField source="data" sortable={false} />
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="email" />
      </ReferenceField>
      {Settings.debugging() && (
        <Debug label="Log">
          <LogLink />
        </Debug>
      )}
    </MyList>
  );
};

export const TowerHistoryListView = () => {
  const { towerId } = useParams();

  return <TowerHistoryList towerId={towerId} />;
};
