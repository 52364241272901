import { TextField, TabbedShowLayout, DateField } from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { MyList } from "../../ra-lb-tools/components/myList";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { MyCreate as Create } from "../../ra-lb-tools/components/myMutator";
import { OrganizationField, OrganizationInput } from "./organizations";
import { PlanField, PlanInput } from "./plans";

export const PlanSubscriptionIcon = BusinessIcon;

export const PlanSubscriptionList = (props) => (
  <MyList {...props} resource="plan-subscriptions">
    <TextField source="id" />
    <OrganizationField source="organizationId" />
    <PlanField source="planId" />
    <TextField source="processorId" />
    <DateField source="createdAt" showTime />
    <DateField source="updatedAt" showTime />
  </MyList>
);
export const PlanSubscriptionShow = () => {
  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <OrganizationField source="organizationId" />
              <PlanField source="planId" />
              <TextField source="processorId" />
              <DateField source="createdAt" showTime />
              <DateField source="updatedAt" showTime />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const PlanSubscriptionCreate = (props) => {
  return (
    <Create {...props}>
      <OrganizationInput source="organizationId" />
      <PlanInput source="planId" />
    </Create>
  );
};

export const PlanSubscriptionInput = (props) => {
  return (
    <MyReferenceInput
      source="planSubscriptionId"
      reference="planSubscriptions"
      {...props}
    />
  );
};

export const PlanSubscriptionField = (props) => {
  return (
    <MyReferenceField
      source="planSubscriptionId"
      reference="planSubscriptions"
      {...props}
    />
  );
};
