import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Button,
  Snackbar,
  Alert,
} from "@mui/material/";
import {
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  BooleanInput,
  useRecordContext,
  useGetList,
  required,
} from "react-admin";
import HelpIcon from "@mui/icons-material/Help";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useState, useEffect } from "react";
import { lowlockerTooltipImageLink } from "../../config/resources";
import {
  addLocker,
  getNextRow,
  removeLocker,
  applyLockerUpdates,
} from "./lockersHelpers";
import { v4 as uuidv4 } from "uuid";
import { useRemoveStyles } from "../../theme/removeLockerButtonStyle";
import { getAccessModeName } from "./lockersHelpers";
import { accessModeChoices } from "../lockers";
import { Settings } from "../../ra-lb-tools/utils/settings";
import { useFormContext } from "react-hook-form";

const getTypeLabel = (choice) =>
  choice.name +
  (Settings.debugging() ? ` (${choice.relHeight}x${choice.relWidth})` : "");

const propagateLayoutChange = (
  change,
  updatedLockers,
  changedLocker,
  types,
  setValue
) => {
  if (["rowPosition", "type"].includes(change.field)) {
    const updatedLockerIndex = updatedLockers.findIndex(
      (locker) => locker.id == changedLocker.id
    );

    let deltaRow = 0;

    for (
      let index = updatedLockerIndex + 1;
      index < updatedLockers.length;
      index++
    ) {
      const currentLocker = updatedLockers[index];

      if (currentLocker["colPosition"] != changedLocker["colPosition"])
        continue;

      if (!deltaRow) {
        const nextRow = getNextRow(
          updatedLockers.slice(0, index),
          changedLocker["colPosition"],
          types
        );

        deltaRow = nextRow - currentLocker["rowPosition"];
      }

      currentLocker["rowPosition"] += deltaRow;

      setValue(`rowPosition-${currentLocker.id}`, currentLocker["rowPosition"]);
    }
  }
};

const onLockerUpdate = (
  changedLocker,
  change,
  lockers,
  setLockers,
  onChange,
  types,
  setValue
) => {
  const updatedLockers = lockers.map((currentLocker) => {
    if (currentLocker.id === changedLocker.id) {
      return changedLocker;
    }
    return currentLocker;
  });

  propagateLayoutChange(change, updatedLockers, changedLocker, types, setValue);

  applyLockerUpdates(updatedLockers, setLockers, onChange);
};

export const SpecTable = ({
  source,
  highlight,
  onMouseEnter,
  onMouseLeave,
  setRowRef,
}) => {
  const record = useRecordContext();
  const specs = record[source];

  const { data: types } = useGetList("locker-types");

  const getTypeName = (id) => {
    if (!types) return "Loading...";
    const lockerType = types.find((type) => type.id === id);
    return getTypeLabel(lockerType);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Is Low Locker</TableCell>
            <TableCell>Physical ID</TableCell>
            <TableCell>Access Mode</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Column Position</TableCell>
            <TableCell>Row Position</TableCell>
            <TableCell>Offset Position</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {specs.map((spec) => (
            <TableRow
              key={spec.physicalId}
              style={{
                border:
                  "2px " +
                  (highlight.includes(spec.physicalId)
                    ? "double black"
                    : "solid transparent"),
              }}
              onMouseEnter={() => onMouseEnter(spec)}
              onMouseLeave={() => onMouseLeave(spec)}
              ref={(refElem) =>
                setRowRef && setRowRef(spec.physicalId, refElem)
              }
            >
              <TableCell>{getTypeName(spec.type)}</TableCell>
              <TableCell>{spec.isLowLocker ? "Yes" : "No"}</TableCell>
              <TableCell>{spec.physicalId}</TableCell>
              <TableCell>{getAccessModeName(spec.accessModeId)}</TableCell>
              <TableCell>{spec.name}</TableCell>
              <TableCell>{spec.colPosition}</TableCell>
              <TableCell>{spec.rowPosition}</TableCell>
              <TableCell>{spec.offsetPosition}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const LockerGroupCreate = ({
  numColumns,
  onChange,
  highlight,
  onMouseEnter,
  onMouseLeave,
  setRowRef,
}) => {
  const [lockers, setLockers] = useState([]);
  const removeClasses = useRemoveStyles();
  const [showPhysicalIdError, setShowPhysicalIdError] = useState(false);
  const { setValue } = useFormContext();
  const { data: types } = useGetList("locker-types");

  const [prevNumColumns, setPrevNumColumns] = useState(numColumns);

  useEffect(() => {
    if (prevNumColumns !== numColumns) {
      const updatedLockers = lockers.filter(
        (locker) => locker.colPosition <= numColumns
      );
      applyLockerUpdates(updatedLockers, setLockers, onChange);
      setPrevNumColumns(numColumns);
    }
  }, [numColumns]);

  const onAddLocker = (column) => {
    function handleDuplicateError() {
      setShowPhysicalIdError(true);
    }
    addLocker(
      lockers,
      setLockers,
      onChange,
      column,
      handleDuplicateError,
      types
    );
  };

  const onRemoveLocker = (id) =>
    removeLocker(lockers, setLockers, onChange, id);

  const _onLockerUpdate = (changedLocker, change) =>
    onLockerUpdate(
      changedLocker,
      change,
      lockers,
      setLockers,
      onChange,
      types,
      setValue
    );

  const handlePhysicalIdChange = (id, newPhysicalId) => {
    const updatedLockers = lockers.map((locker) => {
      if (locker.id === id) {
        return {
          ...locker,
          physicalId: newPhysicalId,
        };
      }
      return locker;
    });

    setLockers(updatedLockers);
    onChange(updatedLockers);
  };

  const renderLockerGroup = () => {
    var columns = [];
    for (let column = 1; column <= numColumns; column++) {
      const columnLockers = lockers.filter(
        (locker) => locker.colPosition === column
      );
      const columnRender = (
        <div key={column} className="LockerGroupCreateColumn">
          <label>Locker Spec Column {column}</label>
          <ul style={{ listStyleType: "none" }}>
            {columnLockers.map((locker) => (
              <li
                key={locker.id}
                style={{
                  display: "flex",
                  gap: "10px",
                  border:
                    "2px " +
                    (highlight.includes(locker.physicalId)
                      ? "double black"
                      : "solid transparent"),
                }}
                onMouseEnter={() => onMouseEnter(locker)}
                onMouseLeave={() => onMouseLeave(locker)}
                ref={(refElem) =>
                  setRowRef && setRowRef(locker.physicalId, refElem)
                }
              >
                <NumberInput
                  min={1}
                  label="PhysicalId"
                  source={`physicalId-${locker.id}`}
                  defaultValue={locker.physicalId}
                  validate={required()}
                  onChange={(event) =>
                    handlePhysicalIdChange(
                      locker.id,
                      parseInt(event.target.value)
                    )
                  }
                />
                <ReferenceInput
                  source={`type-${locker.id}`}
                  reference="locker-types"
                  sort={{ field: "sortOrder", order: "ASC" }}
                >
                  <SelectInput
                    optionValue="id"
                    optionText={getTypeLabel}
                    label="Type"
                    validate={required()}
                    defaultValue={locker.type}
                    onChange={(event) =>
                      _onLockerUpdate(
                        { ...locker, type: event.target.value },
                        { field: "type", value: event.target.value }
                      )
                    }
                  />
                </ReferenceInput>
                <Tooltip
                  title={
                    <img
                      src={lowlockerTooltipImageLink}
                      alt="Low Locker"
                      style={{ maxWidth: "100%", objectFit: "contain" }}
                    />
                  }
                >
                  <HelpIcon />
                </Tooltip>
                <BooleanInput
                  label="Low Locker"
                  source={`isLowLocker-${locker.id}`}
                  defaultValue={false}
                  onChange={(event) =>
                    _onLockerUpdate(
                      { ...locker, isLowLocker: event.target.checked },
                      { field: "isLowLocker", value: event.target.checked }
                    )
                  }
                />
                <TextInput label="Name" source={`name-${locker.id}`} />
                <SelectInput
                  source={`accessModeId-${locker.id}`}
                  choices={accessModeChoices}
                  required
                  label="Access Mode"
                  defaultValue={locker.accessModeId}
                  onChange={(event) =>
                    _onLockerUpdate(
                      { ...locker, accessModeId: event.target.value },
                      { field: "accessModeId", value: event.target.value }
                    )
                  }
                ></SelectInput>
                <NumberInput
                  label="Row"
                  source={`rowPosition-${locker.id}`}
                  validate={required()}
                  defaultValue={locker.rowPosition}
                  onChange={(event) =>
                    _onLockerUpdate(
                      {
                        ...locker,
                        rowPosition: parseFloat(event.target.value),
                      },
                      { field: "rowPosition", value: event.target.value }
                    )
                  }
                />
                <NumberInput
                  label="Offset"
                  source={`offsetPosition-${locker.id}`}
                  validate={required()}
                  defaultValue={locker.offsetPosition}
                  onChange={(event) =>
                    _onLockerUpdate(
                      {
                        ...locker,
                        offsetPosition: parseFloat(event.target.value),
                      },
                      { field: "offsetPosition", value: event.target.value }
                    )
                  }
                />
                <BooleanInput
                  options={{ icon: <RemoveCircleIcon /> }}
                  classes={{
                    switchBase: removeClasses.switchBase,
                    track: removeClasses.track,
                  }}
                  source={`deleteLocker-${locker.id}`}
                  onChange={() => {
                    onRemoveLocker(locker.id);
                  }}
                  label={false}
                />
              </li>
            ))}
          </ul>
          <Button onClick={() => onAddLocker(column)}>
            <AddCircleIcon />
          </Button>
          <Snackbar
            open={showPhysicalIdError}
            autoHideDuration={6000}
            onClose={() => setShowPhysicalIdError(false)}
          >
            <Alert
              onClose={() => setShowPhysicalIdError(false)}
              severity="error"
            >
              Error: there are duplicate physicalId, please correct this before
              adding a new locker.
            </Alert>
          </Snackbar>
        </div>
      );

      columns.push(columnRender);
    }
    return columns;
  };

  return <>{renderLockerGroup()}</>;
};

//
export const LockerGroupEdit = ({
  numColumns,
  onChange,
  highlight,
  onMouseEnter,
  onMouseLeave,
  setRowRef,
  towerCount,
}) => {
  const record = useRecordContext();
  const { setValue } = useFormContext();
  var initialNumColumns = numColumns || record["numColumns"];
  const [prevNumColumns, setPrevNumColumns] = useState(numColumns);
  const removeClasses = useRemoveStyles();
  const [showPhysicalIdError, setShowPhysicalIdError] = useState(false);
  const { data: types } = useGetList("locker-types");
  const disabled = towerCount > 0;

  const setIdToLockers = (lockers) => {
    return lockers.map((locker) => ({
      ...locker,
      id: uuidv4(),
    }));
  };
  const [lockers, setLockers] = useState(setIdToLockers(record["lockersSpec"]));

  useEffect(() => {
    if (prevNumColumns !== numColumns) {
      const updatedLockers = lockers.filter(
        (locker) => locker.colPosition <= numColumns
      );
      applyLockerUpdates(updatedLockers, setLockers, onChange);
      setPrevNumColumns(numColumns);
    }
  }, [numColumns]);

  const _onLockerUpdate = (changedLocker, change) =>
    onLockerUpdate(
      changedLocker,
      change,
      lockers,
      setLockers,
      onChange,
      types,
      setValue
    );

  const onAddLocker = (column) => {
    function handleDuplicateError() {
      setShowPhysicalIdError(true);
    }
    addLocker(
      lockers,
      setLockers,
      onChange,
      column,
      handleDuplicateError,
      types
    );
  };

  const onRemoveLocker = (id) => {
    removeLocker(lockers, setLockers, onChange, id);
  };

  const handlePhysicalIdChange = (id, newPhysicalId) => {
    const updatedLockers = lockers.map((locker) => {
      if (locker.id === id) {
        return {
          ...locker,
          physicalId: newPhysicalId,
        };
      }
      return locker;
    });
    applyLockerUpdates(updatedLockers, setLockers, onChange);
  };

  const renderLockerGroup = () => {
    var columns = [];
    for (let column = 1; column <= initialNumColumns; column++) {
      const columnLockers = lockers.filter(
        (locker) => locker.colPosition === column
      );
      const columnRender = (
        <div key={column}>
          <label>Locker Spec Column {column}</label>
          <ul style={{ listStyleType: "none" }}>
            {columnLockers.map((locker) => (
              <li
                key={locker.id}
                style={{
                  display: "flex",
                  gap: "10px",
                  border:
                    "2px " +
                    (highlight.includes(locker.physicalId)
                      ? "double black"
                      : "solid transparent"),
                }}
                onMouseEnter={() => onMouseEnter(locker)}
                onMouseLeave={() => onMouseLeave(locker)}
                ref={(refElem) =>
                  setRowRef && setRowRef(locker.physicalId, refElem)
                }
              >
                <NumberInput
                  min={1}
                  label="PhysicalId"
                  source={`physicalId-${locker.id}`}
                  defaultValue={locker.physicalId}
                  onChange={(event) =>
                    handlePhysicalIdChange(
                      locker.id,
                      parseInt(event.target.value)
                    )
                  }
                  validate={required()}
                />
                <ReferenceInput
                  source={`type-${locker.id}`}
                  reference="locker-types"
                  sort={{ field: "sortOrder", order: "ASC" }}
                >
                  <SelectInput
                    optionValue="id"
                    optionText={getTypeLabel}
                    label="Type"
                    defaultValue={locker.type}
                    onChange={(event) =>
                      _onLockerUpdate(
                        { ...locker, type: event.target.value },
                        { field: "type", value: event.target.value }
                      )
                    }
                    validate={required()}
                  />
                </ReferenceInput>
                <Tooltip
                  title={
                    <img
                      src={lowlockerTooltipImageLink}
                      alt="Low Locker"
                      style={{ maxWidth: "100%", objectFit: "contain" }}
                    />
                  }
                >
                  <HelpIcon />
                </Tooltip>
                <BooleanInput
                  label="Low Locker"
                  source={`isLowLocker-${locker.id}`}
                  defaultValue={locker.isLowLocker}
                  onChange={(event) =>
                    _onLockerUpdate(
                      { ...locker, isLowLocker: event.target.checked },
                      { field: "isLowLocker", value: event.target.checked }
                    )
                  }
                />
                <TextInput
                  label="Name"
                  source={`name-${locker.id}`}
                  defaultValue={locker.name}
                  onChange={(event) =>
                    _onLockerUpdate(
                      { ...locker, name: event.target.value },
                      { field: "name", value: event.target.value }
                    )
                  }
                />
                <SelectInput
                  source={`accessModeId-${locker.id}`}
                  choices={accessModeChoices}
                  required
                  label="Access Mode"
                  defaultValue={locker.accessModeId}
                  onChange={(event) =>
                    _onLockerUpdate(
                      { ...locker, accessModeId: event.target.value },
                      { field: "accessModeId", value: event.target.value }
                    )
                  }
                ></SelectInput>

                <NumberInput
                  label="Row"
                  source={`rowPosition-${locker.id}`}
                  defaultValue={locker.rowPosition}
                  validate={required()}
                  onChange={(event) =>
                    _onLockerUpdate(
                      {
                        ...locker,
                        rowPosition: parseFloat(event.target.value),
                      },
                      {
                        field: "rowPosition",
                        value: parseFloat(event.target.value),
                      }
                    )
                  }
                />
                <NumberInput
                  label="Offset"
                  source={`offsetPosition-${locker.id}`}
                  defaultValue={locker.offsetPosition}
                  validate={required()}
                  onChange={(event) =>
                    _onLockerUpdate(
                      {
                        ...locker,
                        offsetPosition: parseFloat(event.target.value),
                      },
                      {
                        field: "offsetPosition",
                        value: parseFloat(event.target.value),
                      }
                    )
                  }
                />

                <BooleanInput
                  options={{ icon: <RemoveCircleIcon /> }}
                  classes={{
                    switchBase: !disabled && removeClasses.switchBase,
                    track: removeClasses.track,
                  }}
                  source={`deleteLocker-${locker.id}`}
                  onChange={() => {
                    onRemoveLocker(locker.id);
                  }}
                  label={false}
                  disabled={disabled}
                />
              </li>
            ))}
          </ul>
          <Button onClick={() => onAddLocker(column)} disabled={disabled}>
            <AddCircleIcon />
          </Button>
          <Snackbar
            open={showPhysicalIdError}
            autoHideDuration={6000}
            onClose={() => setShowPhysicalIdError(false)}
          >
            <Alert
              onClose={() => setShowPhysicalIdError(false)}
              severity="error"
            >
              Error: there are duplicate physicalId, please correct this before
              adding a new locker.
            </Alert>
          </Snackbar>
        </div>
      );

      columns.push(columnRender);
    }
    return columns;
  };

  return <>{renderLockerGroup()}</>;
};
