import { Create, Edit, SimpleForm, useNotify } from "react-admin";
import { errorHandlingFactory } from "../utils/errors";
import { Settings } from "../utils/settings";
import { Debug } from "./debug";
import { Card, CardContent } from "@mui/material";
import { JsonStyled } from "./jsonStyled";
import { useLocation } from "react-router-dom";

export const MyEdit = (props) => <MyMutator {...props} mutatorType="edit" />;
export const MyCreate = (props) => (
  <MyMutator {...props} mutatorType="create" />
);

const MyMutator = (props) => {
  const Mutator = props.mutatorType == "edit" ? Edit : Create;

  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  const processedProps = Object.assign(
    {
      mutationMode: Settings.getValue("pessimisticSave")
        ? "pessimistic"
        : "optimistic",
    },
    props,
    {
      formProps: null,
      children: null,
    }
  );

  const mutationOptions = { onError, ...props.mutationOptions };

  return (
    <>
      <Mutator {...processedProps} mutationOptions={mutationOptions}>
        <SimpleForm {...props.formProps}>{props.children}</SimpleForm>
      </Mutator>
      <Debug>
        <Card>
          <CardContent>
            <h3>Processed Props</h3>
            <JsonStyled data={processedProps} />
            <h3>Location Info</h3>
            <JsonStyled data={useLocation()} />
          </CardContent>
        </Card>
      </Debug>
    </>
  );
};

MyMutator.defaultProps = {
  redirect: -1,
  mutatorType: "edit",
};
