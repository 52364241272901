import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "react-admin";
import { toClipboard } from "../utils/tools";

export const CopyToClipboardButton = (props) => {
  return (
    <Button
      onClick={() => toClipboard(props.data)}
      title="Copy to clipboard"
      {...props}
    >
      <ContentCopyIcon />
    </Button>
  );
};
